<template>
    <div class="page-project hero-wrapper">
        <div class="columns is-multiline">
            <div class="column is-9">
                <h2 class="title">{{ title }}</h2>

                <h3><strong>Description</strong></h3>
                <p class="is-size-10 has-text-grey">
                <div v-html="description"></div>
                </p>

                <br>
                <h3>
                    <p class="is-size-10 has-text-grey">
                        <strong>Keywords</strong> {{ keywords }}
                    </p>
                </h3>

                <div class="columns is-multiline">
                    <div class="column">

                        <div class="columns is-multiline">
                            <div class="column">
                                <br>
                                <h3><strong>Experiment Type</strong></h3>
                                <p class="is-size-10 has-text-grey" v-for="experiment_type in experiment_types">
                                    {{ experiment_type['name'] }}
                                </p>
                            </div>

                            <div class="column">
                                <br>
                                <h3><strong>Experiment Scale</strong></h3>
                                <p class="is-size-10 has-text-grey" v-for="experiment_scale in experiment_scales">
                                    {{ experiment_scale['name'] }}
                                </p>
                            </div>

                            <div class="column">
                                <br>
                                <router-link to="/taxonomy">
                                    <strong>Experimental Test Taxonomy</strong>
                                </router-link>
                                <p class="is-size-10 has-text-grey">
                                    {{ taxonomyString }}
                                </p>
                            </div>
                        </div>

                        <div class="columns is-multiline">
                            <div class="column is-half" style="display: none">
                                <br>
                                <h3><strong>Specimen Type</strong></h3>
                                <p class="is-size-10 has-text-grey" v-for="experiment_type in experiment_types">
                                    {{ experiment_type['name'] }}
                                </p>
                            </div>

                            <div class="column is-half" style="display: none">
                                <br>
                                <h3><strong>Specimen Sub-Type</strong></h3>
                                <p class="is-size-10 has-text-grey" v-for="experiment_type in experiment_types">
                                    {{ experiment_type['name'] }}
                                </p>
                            </div>

                        </div>

                        <br>
                        <h3><strong>How to Cite This Work</strong></h3>
                        <p class="is-size-10 has-text-grey">
                        <div v-html="publications"></div>
                        </p>

                    </div>
                </div>
            </div>

            <div class="column is-3">
                <h2 class="subtitle">Dataset PI(s)</h2>
                <p class="is-size-10 has-text-grey" v-for="experiment_pi in project.experiment_pi"
                    v-bind:key="experiment_pi.id" v-bind:experiment_pi="experiment_pi">
                    <router-link v-bind:to="experiment_pi.get_absolute_url">
                        <strong>{{ experiment_pi.first_name['0'] }}.</strong> <strong>{{ experiment_pi.last_name
                            }}</strong>
                    </router-link>
                </p>

                <hr>
                <h2 class="subtitle">Dataset Facility</h2>
                <div v-if="project && project.experiment_facility && Object.keys(project.experiment_facility).length">
                    <p class="is-size-10 has-text-grey" v-for="facility in project.experiment_facility"
                        v-bind:key="facility.id" v-bind:facility="facility">
                        <router-link v-bind:to="facility.get_absolute_url">
                            <strong>{{ facility.name }}</strong>
                        </router-link>
                    </p>
                </div>
                <div v-else>
                    <p><strong>Collection</strong></p>
                </div>

                <hr>
                <h2 class="subtitle">Dates of Experiment</h2>
                <p class="is-size-10 has-text-grey">
                    <strong>{{ yearOfExperiment }}</strong>
                </p>

                <hr>
                <h2 class="subtitle">License</h2>
                <p class="is-size-10 has-text-grey" v-for="license in licenses">
                    <strong><a :href="license['url']" target="_blank" rel="noopener noreferrer">
                            {{ license['name'] }}
                        </a></strong>
                </p>

                <hr>
                <h2 class="subtitle">Dataset DOI</h2>
                <p class="is-size-10 has-text-grey">
                    <strong>
                        <a :href="'https://doi.org/' + doi" target="_blank" rel="noopener noreferrer">
                            https://doi.org/{{ doi }}
                        </a>
                    </strong>
                </p>

                <hr>
                <div class="control download-button">
                    <div v-if="!loading" v-for="(filename, index) in filePaths" :key="index"
                        style="align-items: center;">
                        <div v-if="!filename.startsWith('http')">
                            <p class="is-size-10 has-text-grey mr-2">
                                <strong>{{ filename }}: {{ fileSizes[index] }}GB</strong>
                            </p>

                            <a class="button primary-button" v-if="!loading" @click="downloadData(filename)"
                                style="color: #2B2B2B">Download</a>
                        </div>
                        <div v-else>
                            <div>
                                <p class="is-size-10 has-text-grey mr-2">
                                    <strong>{{ zenodoFile[index] }}: {{ fileSizes[index] }}GB</strong>
                                </p>
                            </div>
                            <div>
                                <a class="button primary-button" v-if="!loading"
                                    @click="downloadData(filename, zenodoFile[index])"
                                    style="color: #2B2B2B">Download</a>
                            </div>
                        </div>

                        <hr>
                    </div>

                    <div v-else>
                        <p class="is-size-10 has-text-grey">
                            <strong>Almost there! Please wait! Don't close tab!</strong>
                        </p>
                        <div class="progress">
                            <div class="button progress-bar-fill" role="progressbar" :style="{ width: progress + '%' }"
                                :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100">{{ progress }}%</div>
                        </div>
                    </div>

                    <div v-if="filePaths.length < 1">
                        Dataset not found, try reloading or
                        <router-link to="/publish" style="text-decoration: underline;">
                            contact us
                        </router-link>
                    </div>
                </div>

                <div class="download-text">
                    <h2 class="subtitle">Download Link</h2>

                    <p class="is-size-10 has-text-grey">
                        <strong>To ensure a smooth downloading experience, we recommend accessing this link from a
                            computer
                            rather than a mobile device</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { marked } from 'marked'
import axios from 'axios'
import { toast } from 'bulma-toast'
import { useHead } from '@vueuse/head'
import { ref, onMounted, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getLocalStorage, addAttributesToLinks, removeExtraSlashes } from '@/assets/methods.js'

const route = useRoute()
const store = useStore()

const title = ref('')
const description = ref('')
const keywords = ref('')
const doi = ref('')
const publications = ref()
const experiment_types = ref([])
const project = ref({})
const taxonomyString = ref(null)
const yearOfExperiment = ref(null)
const downloadLink = ref(null)
const filePaths = ref([])
const absoluteFilePaths = ref([])
const distributions = ref([])
const fileSizes = ref([])
const loading = ref(false)
const progress = ref(0)
const absolute_url = ref('')
const project_id = ref(null)
const experiment_pi = ref([])
const zenodoFile = ref([])
const experiment_scales = ref([])
const experiment_facility = ref({})
const licenses = ref([])

useHead({
    title: title,
    meta: () => [
        { name: 'description', content: description },
        { name: 'citation_title', content: title },
        { name: 'citation_doi', content: doi },
        { name: 'citation_keywords', content: keywords },
        { name: 'citation_abstract_html_url', content: absolute_url },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        { property: 'og:url', content: absolute_url },
        { property: 'og:site_name', content: store.state.appName },
    ],
    link: () => [
        { rel: 'canonical', href: absolute_url },
        ...absoluteFilePaths.value,
    ],
    script: () => [
        {
            type: 'application/ld+json',
            innerHTML: {
                '@context': 'https://schema.org',
                '@id': 'https://doi.org/' + doi.value,
                "@type": "https://schema.org/Dataset",
                name: title,
                author: experiment_pi,
                contentSize: `${computed(() => fileSizes.value.reduce((acc, curr) => acc + curr, 0)).value} GB`,
                size: `${computed(() => fileSizes.value.reduce((acc, curr) => acc + curr, 0)).value} GB`,
                creator: experiment_pi,
                description: description,
                distribution: distributions,
                identifier: 'https://doi.org/' + doi.value,
                url: absolute_url,
                keywords: keywords,
                name: title,
                version: 'V1.0',
            },
        }
    ]
})

const displayedText = computed(() => {
    if (!showFullText.value) {
        return project.value.experiment_description.slice(0, 300)
    } else {
        return project.value.experiment_description
    }
})

const readMoreText = computed(() => showFullText.value ? 'less' : 'more')

async function fetchMetaData() {
    store.commit('setIsLoading', true)
    project_id.value = route.params.project_id

    const datasets = getLocalStorage('datasets')

    if (datasets !== null) {
        project.value = datasets.find(p => p.id === parseInt(project_id.value))
    }

    if (project.value === null || project.value === undefined || datasets === null) {
        await axios
            .get(`/api/v1/datasets/${project_id.value}`)
            .then(response => {
                project.value = response.data
            })
            .catch(error => {
                console.log(error)
            })
    }

    title.value = project.value.experiment_title
    keywords.value = project.value.keywords
    doi.value = project.value.doi
    description.value = marked(project.value.experiment_description)
    description.value = addAttributesToLinks(description.value)
    publications.value = marked(project.value.publications)
    publications.value = addAttributesToLinks(publications.value)
    taxonomyString.value = project.value.taxonomy.slice(1, -1)
    taxonomyString.value = removeExtraSlashes(taxonomyString.value)
    yearOfExperiment.value = getExperimentYears(
        project.value.start_of_experiment,
        project.value.end_of_experiment,
        project.value.year_of_experiment
    )
    experiment_types.value = project.value.experiment_type
    experiment_scales.value = project.value.experiment_scale
    experiment_pi.value = project.value.experiment_pi
    experiment_facility.value = project.value.experiment_facility
    licenses.value = project.value.license
    absolute_url.value = store.state.url + project.value.get_absolute_url
    downloadLink.value = project.value.get_absolute_url + "download"
    filePaths.value = project.value.url ? project.value.url.split(/[,\s;]+/) : [];

    await getFiles(filePaths.value, doi.value)

    experiment_pi.value = project.value.experiment_pi.map(pi => ({
        '@type': 'Person',
        'familyName': pi.last_name,
        'name': pi.first_name,
    }))

    store.commit('setIsLoading', false)
}

async function getFiles(paths, doi) {
    if (paths.length > 0) {
        // Datasets hosted locally
        absoluteFilePaths.value = paths.map(filename => ({
            rel: 'alternate',
            type: 'application/zip',
            href: `${store.state.url}/api/v1/datasets/${project_id.value}/download/${removeFileExtension(filename)}`
        }))

        distributions.value = paths.map(filename => ({
            "@type": 'DataDownload',
            encodingFormat: 'application/zip',
            contentUrl: `${store.state.url}/api/v1/datasets/${project_id.value}/download/${removeFileExtension(filename)}`
        }))

        await getDatasetFilesizes(paths);

    } else {
        // zenodo DOIs
        // Datasets hosted externally
        const id = doi.split('zenodo.')[1]
        const url = `https://zenodo.org/api/records/${id}`

        await axios.get(url)
            .then(response => {
                const data = response.data;
                for (const [key, content] of Object.entries(data.files)) {
                    const filename = content.key
                    const filesize = content.size
                    const downloadLink = content.links.self

                    fileSizes.value.push((filesize / Math.pow(10, 9)).toFixed(3))
                    filePaths.value.push(downloadLink)
                    zenodoFile.value.push(filename)
                }
            })
            .catch(error => {
                console.error('Failed to retrieve zenodo metadata:', error);
            });
    }
}

onMounted(async () => {
    await fetchMetaData()
})

async function downloadData(filename, shortName = null) {
    loading.value = true

    if (isNaN(project.value.downloads) || project.value.downloads < 0) {
        project.value.downloads = 0
    }

    toast({
        message: 'Downloading: Please Wait!',
        type: 'is-success',
        dismissible: true,
        pauseOnHover: true,
        duration: 10000,
        position: 'bottom-right',
    })

    try {
        if (filename.startsWith('http')) {
            await axios.get(filename, { responseType: 'blob' })
                .then(fileResponse => {
                    const blob = new Blob([fileResponse.data], { type: fileResponse.headers['content-type'] });
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = shortName;
                    link.click();
                })

        } else {

            const project_id = route.params.project_id
            const filenameBase = removeFileExtension(filename)

            const data = project.value

            const response = await axios({
                url: `/api/v1/datasets/${project_id}/download/${filenameBase}`,
                data: data,
                method: "GET",
                responseType: 'blob',
                onDownloadProgress: (progressEvent) => {
                    progress.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                }
            })

            let blob = new Blob([response.data], { type: response.headers.contentType })
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = filename
            link.click()
        }

    } catch (error) {
        console.log(error)
        toast({
            message: 'Something went wrong. Please try again',
            type: 'is-danger',
            dismissible: true,
            pauseOnHover: true,
            duration: 4000,
            position: 'bottom-right',
        })
    } finally {
        loading.value = false
        progress.value = 0
        project.value.downloads++
    }
}

async function getDatasetFilesizes(paths) {
    const promises = paths.map(filepath => singleFileSize(filepath))
    fileSizes.value = await Promise.all(promises)
}

async function singleFileSize(filepath) {
    let filesize = null
    filepath = removeFileExtension(filepath)

    try {
        const response = await axios.get(`/api/v1/dataset-file/${filepath}`)
        filesize = response.data.size_gb
    } catch (error) {
        console.log(error)
    }

    return filesize
}

function removeFileExtension(fileName) {
    const lastDotIndex = fileName.lastIndexOf('.')
    if (lastDotIndex !== -1) {
        return fileName.substring(0, lastDotIndex)
    }
    return fileName
}

function getExperimentYears(start, end, year) {
    if (start !== null && end !== null) {
        return `${start} - ${end}`
    } else if (start !== null) {
        return `${start}`
    } else if (end !== null) {
        return `${end}`
    } else if (year !== null) {
        return `${year}`
    } else {
        return ""
    }
}
</script>

<style>
@media(max-width: 768px) {
    .download-button {
        display: none;
    }
}

@media (min-width: 761px) {
    .download-text {
        display: none;
    }
}

ul {
    list-style-type: disc !important;
    margin-left: 20px !important;
    padding-left: 0 !important;
}

li {
    margin-bottom: 5px !important;
}
</style>

<style scoped>
.progress {
    width: 70%;
}

.progress-bar-fill {
    height: 100%;
}
</style>